import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt , faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../hooks/useAuth';
import './ManageUsers.css';

const ManageUsers = () => {
  const { user } = useAuth();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [users, setUsers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNo: '',
    email: '',
    registrationNo: '',
    hospitalName: '',
    speciality: '',
    password: '',
  });

  useEffect(() => {

    // Fetch existing users from the backend
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/api/users`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          console.error('Fetched data is not an array:', data);
          setUsers([]);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]);
      }
    };

    fetchUsers();
  }, []);

  const handleAddOrUpdateUser = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const url = editingIndex !== null 
      ? `${apiBaseUrl}/api/users/${users[editingIndex].id}`
      : `${apiBaseUrl}/api/users`;
    const method = editingIndex !== null ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(formData),
      });
      const newUser = await response.json();
      if (editingIndex !== null) {
        const updatedUsers = users.map((user, index) =>
          index === editingIndex ? newUser : user
        );
        setUsers(updatedUsers);
        setEditingIndex(null);
      } else {
        setUsers([...users, newUser]);
      }
      setFormData({
        firstName: '',
        lastName: '',
        phoneNo: '',
        email: '',
        registrationNo: '',
        hospitalName: '',
        speciality: '',
        password: '',
      });
    } catch (error) {
      console.error('Error adding/updating user:', error);
    }
  };

  const handleToggleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/api/users/${id}/toggle-delete`, {
        method: 'PATCH',
        headers: {
          'x-auth-token': token,
        }
      });
      const updatedUser = await response.json();
      const updatedUsers = users.map((user) =>
        user.id === id ? updatedUser : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error toggling delete status:', error);
    }
  };

  const handleEdit = (index) => {
    const user = users[index];
    setFormData({
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNo: user.phone_no,
      email: user.email,
      registrationNo: user.registration_no,
      hospitalName: user.hospital_name,
      speciality: user.speciality,
      password: '',
    });
    setEditingIndex(index);
  };

  const handleCancelEdit = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phoneNo: '',
      email: '',
      registrationNo: '',
      hospitalName: '',
      speciality: '',
      password: '',
    });
    setEditingIndex(null);
  };
  // If user is not a super admin, early return
  if (!user || !user.isSuperAdmin) {
    return (<div className="manage-users">
      <h2>You do not have permission to view this page.</h2>
    </div>
    );
  }
  return (
    <div className="manage-users">
      <h2>{editingIndex !== null ? 'EDIT DOCTOR\'S INFORMATION' : 'ADD DOCTOR\'S INFORMATION'}</h2>
      <form onSubmit={handleAddOrUpdateUser} className="manage-users-form">
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <label htmlFor="lastName">Last Name</label>
        </div>
        <div className="form-group">
        <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            placeholder="First name"
            required
          />
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            placeholder="Last name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNo">Phone Number</label>
          <label htmlFor="registrationNo">Registration Number</label>
        </div>
        <div className="form-group">
        <input
            type="text"
            id="phoneNo"
            value={formData.phoneNo}
            onChange={(e) => setFormData({ ...formData, phoneNo: e.target.value })}
            placeholder="Phone number"
            required
          />
          <input
            type="text"
            id="registrationNo"
            value={formData.registrationNo}
            onChange={(e) => setFormData({ ...formData, registrationNo: e.target.value })}
            placeholder="Registration number"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="hospitalName">Hospital Name</label>
          <label htmlFor="speciality">Speciality</label>
        </div>
        <div className="form-group">
          <input
            type="text"
            id="hospitalName"
            value={formData.hospitalName}
            onChange={(e) => setFormData({ ...formData, hospitalName: e.target.value })}
            placeholder="Hospital name"
            required
          />
          <input
            type="text"
            id="speciality"
            value={formData.speciality}
            onChange={(e) => setFormData({ ...formData, speciality: e.target.value })}
            placeholder="Speciality"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <label htmlFor="password">Password</label>
        </div>
        <div className="form-group">
        <input
            type="email"
            id="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            placeholder="Email"
            required
          />
          <input
            type="password"
            id="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            placeholder="Password"
            required
          />
        </div>
        <div className="button-container">
        <button type="submit" className="save-button">
          {editingIndex !== null ? 'Update' : 'Save'}
        </button>
        {editingIndex !== null && (
          <button type="button" className="cancel-button" onClick={handleCancelEdit}>
            Cancel
          </button>
        )}
        </div>
      </form>
      <table className="custom-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone No</th>
            <th>Email</th>
            <th>Registration No</th>
            <th>Hospital Name</th>
            <th>Speciality</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id} className={user.is_deleted ? 'deleted-user' : ''}>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.phone_no}</td>
              <td>{user.email}</td>
              <td>{user.registration_no}</td>
              <td>{user.hospital_name}</td>
              <td>{user.speciality}</td>
              <td>
                <button
                  className="edit-button"
                  onClick={() => handleEdit(index)}
                  disabled={user.is_deleted}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="delete-button"
                  onClick={() => handleToggleDelete(user.id)}
                >
                  <FontAwesomeIcon
                    icon={user.is_deleted ? faCheckSquare : faTrashAlt }
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageUsers;
