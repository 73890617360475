import React, { useEffect, useState, useCallback } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faRepeat, faFileArrowDown, faFileZipper } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const Dashboard = () => {
  useAuth();
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  const [startDate, setStartDate] = useState(sixMonthsAgo.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);
  const [activeTab, setActiveTab] = useState('hipaa');
  const [surgeries, setSurgeries] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState('');
  const [hipaaData, setHipaaData] = useState({
    totalConsents: 0,
    emailSent: 0,
    approved: 0,
    expired: 0,
    pending: 0,
    approvedList: [],
    expiredList: [],
    pendingList: []
  });

  const [surgeryData, setSurgeryData] = useState({
    totalConsents: 0,
    emailSent: 0,
    approved: 0,
    expired: 0,
    pending: 0,
    approvedList: [],
    expiredList: [],
    pendingList: []
  });

  const filterData = useCallback((type, data) => {
    const filteredData = {
      totalConsents: data.totalConsents,
      emailSent: data.emailSent,
      approved: data.approved,
      expired: data.expired,
      pending: data.pending,
      approvedList: [],
      expiredList: [],
      pendingList: []
    };

    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0); // Set start time to the beginning of the day
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999); // Set end time to the end of the day

    filteredData.approvedList = data.approvedList.filter(item => {
      const date = new Date(item.date_when_sent);
      return date >= start && date <= end;
    });

    filteredData.expiredList = data.expiredList.filter(item => {
      const date = new Date(item.date_when_sent);
      return date >= start && date <= end;
    });

    filteredData.pendingList = data.pendingList.filter(item => {
      const date = new Date(item.date_when_sent);
      return date >= start && date <= end;
    });

    if (type === 'hipaa') {
      setHipaaData(filteredData);
    } else if (type === 'surgery') {
      setSurgeryData(filteredData);
    }
  }, [startDate, endDate]);

  const fetchSurgeries = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/surgeries`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setSurgeries(data);
      } else {
        setSurgeries([]);
      }
    } catch (error) {
      console.error('Error fetching surgeries:', error);
      setSurgeries([]);
    }
  }, []);

  const handleGeneratePDF = async (consent) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/api/pdf/generate/${consent.consent_uid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token,
            },
            body: JSON.stringify({
                consentType: activeTab.toUpperCase(), // either 'HIPAA' or 'SURGERY'
            }),
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Consent_${consent.consent_uid}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            console.error('Failed to generate PDF:', response.statusText);
        }
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
  };

  const handleDownloadZip = async () => {
    const consent_uids = activeTab === 'surgery'
    ? surgeryData.approvedList.map((consent) => consent.consent_uid)
    : hipaaData.approvedList.map((consent) => consent.consent_uid);
    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/pdf/generate-zip`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          consent_uids,
          consentType: activeTab.toUpperCase(),
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Consents_${activeTab}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error('Failed to generate ZIP:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating ZIP:', error);
    }
  };

  const fetchHipaaData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/dashboard/hipaa?surgery=${selectedSurgery}`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      });
      const data = await response.json();
      filterData('hipaa', data);
    } catch (error) {
      console.error('Error fetching HIPAA data:', error);
    }
  }, [selectedSurgery, filterData]);

  const fetchSurgeryData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/dashboard/surgery?surgery=${selectedSurgery}`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      });
      const data = await response.json();
      filterData('surgery', data);
    } catch (error) {
      console.error('Error fetching Surgery data:', error);
    }
  }, [selectedSurgery, filterData]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'hipaa') {
      fetchHipaaData();
    } else if (tab === 'surgery') {
      fetchSurgeryData();
    }
  };

  useEffect(() => {
    fetchSurgeries();
  }, [fetchSurgeries]);

  useEffect(() => {
    if (activeTab === 'hipaa') {
      fetchHipaaData();
    } else if (activeTab === 'surgery') {
      fetchSurgeryData();
    }
  }, [startDate, endDate, selectedSurgery, activeTab, fetchHipaaData, fetchSurgeryData]);

  const handleResend = (consent) => {
    navigate('/generate-consent', { state: { consent } });
  };

  const generatePieData = (data) => ({
    labels: ['Total', 'Email Sent', 'Approved', 'Expired', 'Pending'],
    datasets: [
      {
        label: 'Consents',
        data: [data.totalConsents, data.emailSent, data.approved, data.expired, data.pending],
        backgroundColor: ['#36A2EB', '#4BC0C0', '#FFCE56', '#FF6384', '#FF9F40']
      }
    ]
  });

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom'
      }
    }
  };

  const renderConsentTable = (consentList = [], type) => (
    <table className="consent-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Patient Name</th>
          <th>Surgery Type</th>
          <th>Email Sent On</th>
          {type === 'approved' && <th>Approved On</th>}
          {type === 'approved' && <th>Approver Name</th>}
          {type === 'approved' && <th>Relationship</th>}
          {type === 'approved' && <th>Approver Signature</th>}
          {type === 'approved' && <th>Witness Name</th>}
          {type === 'approved' && <th>Witness Signature</th>}
          {type === 'expired' && <th>Action</th>}
          {type === 'approved' && <th>Action <FontAwesomeIcon icon={faFileZipper} onClick={handleDownloadZip} /></th>}
        </tr>
      </thead>
      <tbody>
        {consentList.map((consent) => {
          const patient = consent.Patient || {};
          const surgery = surgeries.find(s => s.id === patient.surgery_type) || {};
          return (
            <tr key={consent.id}>
              <td>{consent.id}</td>
              <td>{`${patient.first_name} ${patient.last_name}`}</td>
              <td>{surgery.name || 'N/A'}</td>
              <td>{formatDate(consent.date_when_sent)}</td>
              {type === 'approved' && (
                <td>{formatDate(consent.date_when_acknowledge)}</td>
              )}
              {type === 'approved' && (
                <td>{consent.approver_name}</td>
              )}
              {type === 'approved' && (
                <td>{consent.relationship}</td>
              )}
              {type === 'approved' && (
                <td>
                  {consent.approver_signature ? (
                    <img src={consent.approver_signature} alt="Approver Signature" width="50" height="30" />
                  ) : (
                    ''
                  )}
                </td>
              )}
              {type === 'approved' && (
                <td>{consent.witness_name}</td>
              )}
              {type === 'approved' && (
                <td>
                  {consent.witness_signature ? (
                    <img src={consent.witness_signature} alt="Witness Signature" width="50" height="30" />
                  ) : (
                    ''
                  )}
                </td>
              )}
              {type === 'expired' && (
                <td>
                    <FontAwesomeIcon 
                        icon={faRepeat} 
                        onClick={() => handleResend(consent)}
                        style={{ cursor: 'pointer' }}
                    />
                </td>
              )}
              {type === 'approved' && (
                <td>
                    <FontAwesomeIcon 
                        icon={faFileArrowDown} 
                        onClick={() => handleGeneratePDF(consent)}
                        style={{ cursor: 'pointer' }}
                    />
                </td>
              )} 
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : '';
  };

  const generatePDF = (consentList, filename, tableName) => {
    const doc = new jsPDF();
    doc.text(tableName, 14, 15);
    const tableColumn = ["ID", "Patient Name", "Surgery Type", "Email Sent On", "Approved On", "Approver Name", "Relationship", "Approver Signature", "Witness Name", "Witness Signature"];
    const tableRows = [];

    consentList.forEach((consent) => {
      const patient = consent.Patient || {};
      const surgery = surgeries.find(s => s.id === patient.surgery_type) || {};
      const consentData = [
        consent.id,
        `${patient.first_name} ${patient.last_name}`,
        surgery.name || 'N/A',
        formatDate(consent.date_when_sent),
        formatDate(consent.date_when_acknowledge),
        consent.approver_name,
        consent.relationship,
        { content: consent.approver_signature ? ' ' : '', styles: { valign: 'middle' } },
        consent.witness_name,
        { content: consent.witness_signature ? ' ' : '', styles: { valign: 'middle' } }
      ];
      tableRows.push(consentData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
      didDrawCell: (data) => {
        if (data.section === 'body') {
          const consent = consentList[data.row.index];
          if (consent) {
            const cellHeight = data.cell.height - 4;
            if (data.column.index === 7 && consent.approver_signature) {
              doc.addImage(consent.approver_signature, 'PNG', data.cell.x + 2, data.cell.y + 2, cellHeight, cellHeight);
            }
            if (data.column.index === 9 && consent.witness_signature) {
              doc.addImage(consent.witness_signature, 'PNG', data.cell.x + 2, data.cell.y + 2, cellHeight, cellHeight);
            }
          }
        }
      }
    });

    doc.save(`${filename}.pdf`);
  };

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      
      <div className="filters">
        <label>Start Date: </label>
        <input 
          type="date" 
          value={startDate} 
          onChange={(e) => {
            const newStartDate = e.target.value;
            if (new Date(newStartDate) <= new Date(endDate) && new Date(newStartDate) <= new Date()) {
              setStartDate(newStartDate);
              filterData(activeTab, activeTab === 'hipaa' ? hipaaData : surgeryData);
            }
          }} 
        />
        <label>End Date: </label>
        <input 
          type="date" 
          value={endDate} 
          onChange={(e) => {
            const newEndDate = e.target.value;
            if (new Date(newEndDate) >= new Date(startDate) && new Date(newEndDate) <= (new Date()).setDate(today.getDate() + 1)) {
              setEndDate(newEndDate);
              filterData(activeTab, activeTab === 'hipaa' ? hipaaData : surgeryData);
            }
          }} 
        />
      </div>

      <div className="filters">
        <label>Surgery: </label>
        <select
          value={selectedSurgery}
          onChange={(e) => {
            setSelectedSurgery(e.target.value);
            filterData(activeTab, activeTab === 'hipaa' ? hipaaData : surgeryData);
          }}
        >
          <option value="">All Surgeries</option>
          {surgeries.map((surgery) => (
            <option key={surgery.id} value={surgery.id}>
              {surgery.name}
            </option>
          ))}
        </select>
      </div>

      <div className="tabs">
        <button className={activeTab === 'hipaa' ? 'active' : ''} onClick={() => handleTabChange('hipaa')}>HIPAA Consents</button>
        <button className={activeTab === 'surgery' ? 'active' : ''} onClick={() => handleTabChange('surgery')}>Surgery Consents</button>
      </div>

      {activeTab === 'hipaa' && (
        <div className="section">
          <div className="chart-container">
            <Pie data={generatePieData(hipaaData)} options={pieOptions} width={100} height={50} />
          </div>
          <h3>Approved Consents <FontAwesomeIcon icon={faDownload} onClick={() => generatePDF(hipaaData.approvedList, 'hipaa_approved', 'Approved HIPAA Consents')} /></h3>
          {renderConsentTable(hipaaData.approvedList, 'approved')}

          <h3>Pending Consents <FontAwesomeIcon icon={faDownload} onClick={() => generatePDF(hipaaData.pendingList, 'hipaa_pending', 'Pending HIPAA Consents')} /></h3>
          {renderConsentTable(hipaaData.pendingList)}

          <h3>Expired Consents <FontAwesomeIcon icon={faDownload} onClick={() => generatePDF(hipaaData.expiredList, 'hipaa_expired', 'Expired HIPAA Consents')} /></h3>
          {renderConsentTable(hipaaData.expiredList, 'expired')}
        </div>
      )}

      {activeTab === 'surgery' && (
        <div className="section">
          <div className="chart-container">
            <Pie data={generatePieData(surgeryData)} options={pieOptions} width={100} height={50} />
          </div>
          <h3>Approved Consents <FontAwesomeIcon icon={faDownload} onClick={() => generatePDF(surgeryData.approvedList, 'surgery_approved', 'Approved Surgery Consents')} /></h3>
          {renderConsentTable(surgeryData.approvedList, 'approved')}

          <h3>Pending Consents <FontAwesomeIcon icon={faDownload} onClick={() => generatePDF(surgeryData.pendingList, 'surgery_pending', 'Pending Surgery Consents')} /></h3>
          {renderConsentTable(surgeryData.pendingList)}

          <h3>Expired Consents <FontAwesomeIcon icon={faDownload} onClick={() => generatePDF(surgeryData.expiredList, 'surgery_expired', 'Expired Surgery Consents')} /></h3>
          {renderConsentTable(surgeryData.expiredList, 'expired')}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
