import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Fonts.css'; // Import the Fonts.css file
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faTrashAlt  } from '@fortawesome/free-solid-svg-icons';

// Add the icons you will use to the library
library.add(faEdit, faTrashAlt );

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
