// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import surgeryBg from '../assets/surgery-bg.png';
import patientBg from '../assets/patient-bg.png';
import doctorsBg from '../assets/doctors-bg.png';
import headerBg from '../assets/header-bg.png';
import './Header.css';

const Header = () => {
  const location = useLocation();
  const [backgroundImage, setBackgroundImage] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDirectAccess, setIsDirectAccess] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case '/add-surgery':
      case '/surgery-consent-template':
        setBackgroundImage(`url(${surgeryBg})`);
        break;
      case '/generate-consent':
        setBackgroundImage(`url(${patientBg})`);
        break;
      case '/manage-users':
        setBackgroundImage(`url(${doctorsBg})`);
        break;
      default:
        setBackgroundImage(`url(${headerBg})`);
        break;
    }

    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        window.location.href = '/';
      } else {
        setIsSuperAdmin(decodedToken.isSuperAdmin);
      }
    } else {
      setIsDirectAccess(true);
    }
  }, [location]);

  const handleLogout = () => {
    // Perform logout actions here, such as clearing user session/storage
    // After logout, redirect to the login page
    // Remove the token from localStorage
    localStorage.removeItem('token');
    window.location.href = "/logout";
  };

  return (
    <header className="header" style={{ backgroundImage }}>
      <div className="header-container">
        <div className="logo" />
        {!isDirectAccess && (
          <button onClick={handleLogout}>Logout</button>
        )}
      </div>
      {!isDirectAccess && (
        <nav className="nav">
          <ul className="nav-menu">
            {!isSuperAdmin && (
              <>
                <li><Link to="/dashboard">Dashboard</Link></li>
                <li><Link to="/add-surgery">Add Surgery</Link></li>
                <li><Link to="/surgery-consent-template">Surgery Consent Templates</Link></li>
                <li><Link to="/generate-consent">Generate Consent</Link></li>
              </>
            )}
            {isSuperAdmin && (
              <>
                <li><Link to="/manage-users">Manage Users</Link></li>
              </>
            )}
            <li><Link to="/reset-password">Reset Password</Link></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
