// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './Layout.css';

const Layout = () => {
  return (
    <div className='main-wrapper'>
      
      
      <main>
      <Header />
        <div className='side-padding-left'/>
        <div className='outlet-wrapper'>
          <Outlet />
        </div>
        <div className='side-padding-right'/>
        <Footer />
        </main>
      
    </div>
  );
};

export default Layout;
