import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt , faCheckSquare  } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../hooks/useAuth';
import './AddSurgery.css';

const AddSurgery = () => {
  useAuth();

  const [surgeryName, setSurgeryName] = useState('');
  const [surgeries, setSurgeries] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSurgeries = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/api/surgeries`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          setSurgeries(data);
        } else {
          setSurgeries([]);
        }
      } catch (error) {
        console.error('Error fetching surgeries:', error);
        setSurgeries([]);
      }
    };

    fetchSurgeries();
  }, []);

  const handleAddOrUpdateSurgery = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (surgeryName.trim()) {
      if (editingIndex !== null) {
        const id = surgeries[editingIndex].id;
        try {
          const response = await fetch(`${apiBaseUrl}/api/surgeries/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token,
            },
            body: JSON.stringify({ name: surgeryName }),
          });
          const updatedSurgery = await response.json();
          const newSurgeries = surgeries.map((surgery, index) => 
            index === editingIndex ? updatedSurgery : surgery
          );
          setSurgeries(newSurgeries);
          setEditingIndex(null);
        } catch (error) {
          console.error('Error updating surgery:', error);
        }
      } else {
        try {
          const response = await fetch(`${apiBaseUrl}/api/surgeries`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token,
            },
            body: JSON.stringify({ name: surgeryName }),
          });
          const newSurgery = await response.json();
          setSurgeries([...surgeries, newSurgery]);
        } catch (error) {
          console.error('Error adding surgery:', error);
        }
      }
      setSurgeryName('');
    }
  };

  const handleToggleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/api/surgeries/${id}/toggle-delete`, {
        method: 'PATCH',
        headers: {
          'x-auth-token': token,
        },
      });
      const updatedSurgery = await response.json();
      const newSurgeries = surgeries.map((surgery) =>
        surgery.id === updatedSurgery.id ? updatedSurgery : surgery
      );
      setSurgeries(newSurgeries);
    } catch (error) {
      console.error('Error toggling delete status:', error);
    }
  };

  const handleEdit = (index) => {
    setSurgeryName(surgeries[index].name);
    setEditingIndex(index);
  };

  const handleCancelEdit = () => {
    setSurgeryName('');
    setEditingIndex(null);
  };

  return (
    <div className="add-surgery">
      <h2>{editingIndex !== null ? 'EDIT SURGERY' : 'ADD SURGERY'}</h2>
      <form onSubmit={handleAddOrUpdateSurgery} className="add-surgery-form">
        <div className="form-group">
          <label htmlFor="surgeryName">Name of Surgery</label>
        </div>
        <div className="form-group">
          <input
            type="text"
            id="surgeryName"
            value={surgeryName}
            onChange={(e) => setSurgeryName(e.target.value)}
            placeholder="Enter name of Surgery"
            required
          />
          </div>
        <div className="button-container">
        <button type="submit" className="save-button">
          {editingIndex !== null ? 'Update' : 'Save'}
        </button>
        {editingIndex !== null && (
          <button type="button" className="cancel-button" onClick={handleCancelEdit}>
            Cancel
          </button>
        )}
        </div>
      </form>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Name of the Surgery</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {surgeries.map((surgery, index) => (
            <tr key={surgery.id} className={surgery.is_deleted ? 'deleted' : ''}>
              <td>{surgery.name}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(index)} disabled={surgery.is_deleted}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className="delete-button" onClick={() => handleToggleDelete(surgery.id)}>
                  <FontAwesomeIcon icon={surgery.is_deleted ? faCheckSquare : faTrashAlt } />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddSurgery;
